<template>
  <c-box id="listNutritionists">
    <Header logo="hide" :back="true" />
    <c-box :paddingTop="[null, '30px']" paddingBottom="80px">
      <c-box
        w="100%"
        maxWidth="1200px"
        mx="auto"
        v-chakra="{
          '> nav': {
            '@media(max-width: 767px)': {
              display: 'none !important',
            },
          },
        }"
      >
        <Breadcrumb
          :items="[
            { href: '/', label: 'Beranda' },
            { href: '#/nutritionists', label: 'Ahli Gizi' },
            { label: 'Detail Ahli Gizi', isCurrent: true },
          ]"
        />

        <c-box w="100%" maxWidth="1200px" mx="auto" :marginTop="[null, '50px']">
          <c-flex flexWrap="wrap" w="100%">
            <c-box :w="['100%', '40%']">
              <c-box
                :marginBottom="['30px', '0']"
                w="100%"
                :maxWidth="['inherit', '430px']"
                pos="relative"
                :borderRadius="['0', '100%']"
                overflow="hidden"
                paddingBottom="89%"
              >
                <c-image
                  :w="['100%', '100%']"
                  object-fit="cover"
                  h="100%"
                  :src="getPhotoNutritionist(nutritionist.photoUrl)"
                  alt="photo"
                  pos="absolute"
                  top="0"
                  left="0"
                />
                <c-box
                  w="100%"
                  pos="absolute"
                  left="0"
                  bottom="0"
                  px="24px"
                  py="24px"
                  :display="['block', 'none']"
                  background="linear-gradient(180deg, rgba(0, 0, 0, 0) 20.96%, #000000 100%)"
                >
                  <c-heading
                    as="h3"
                    maginBottom="24px"
                    fontSize="36px"
                    lineHeight="54px"
                    fontWeight="700"
                    color="white"
                  >
                    {{ fullname }}
                  </c-heading>
                  <c-text
                    fontFamily="Roboto"
                    fontSize="24px"
                    color="white"
                    marginBottom="20px"
                  >
                    Nama Spesialiasi
                  </c-text>
                  <c-text fontFamily="Roboto" fontSize="16px" color="gray.900">
                    <c-image
                      :src="require('@/assets/icon-star.svg')"
                      alt="icon"
                      display="inline-block"
                      verticalAlign="middle"
                      marginRight="0px"
                    />
                    {{ nutritionist.rating }}/5
                  </c-text>
                </c-box>
              </c-box>
              <c-box
                :display="['none', 'block']"
                textAlign="center"
                marginTop="20px"
              >
                <c-text fontFamily="Roboto" fontSize="16px" color="gray.900">
                  <c-image
                    :src="require('@/assets/icon-star.svg')"
                    alt="icon"
                    display="inline-block"
                    verticalAlign="middle"
                    marginRight="0px"
                  />
                  <c-image
                    :src="require('@/assets/icon-star.svg')"
                    alt="icon"
                    display="inline-block"
                    verticalAlign="middle"
                    marginRight="0px"
                  />
                  <c-image
                    :src="require('@/assets/icon-star.svg')"
                    alt="icon"
                    display="inline-block"
                    verticalAlign="middle"
                    marginRight="0px"
                  />
                  <c-image
                    :src="require('@/assets/icon-star.svg')"
                    alt="icon"
                    display="inline-block"
                    verticalAlign="middle"
                    marginRight="0px"
                  />
                  <c-image
                    :src="require('@/assets/icon-star.svg')"
                    alt="icon"
                    display="inline-block"
                    verticalAlign="middle"
                    marginRight="0px"
                  />
                  {{ nutritionist.rating }}/5
                </c-text>
              </c-box>
            </c-box>
            <c-box :w="['100%', '60%']" px="24px">
              <c-heading
                :display="['none', 'flex']"
                as="h3"
                maginBottom="24px"
                :fontSize="['14px', '36px']"
                :lineHeight="['21px', '54px']"
                fontWeight="700"
                color="black.900"
                justifyContent="space-between"
              >
                {{ fullname }}
                <c-button
                  right-icon="arrow-forward"
                  backgroundColor="secondary.800"
                  color="#927102"
                  borderRadius="100px"
                  fontSize="16px"
                  px="20px"
                  :isDisabled="!availableProgram"
                  @click.prevent="onChooseNutritionist"
                >
                  Pilih
                </c-button>
              </c-heading>
              <c-text
                fontFamily="Roboto"
                fontSize="24px"
                color="brand.900"
                marginBottom="50px"
                :display="['none', 'block']"
              >
                Nama Spesialiasi
              </c-text>
              <c-text
                fontFamily="Roboto"
                fontWeight="500"
                fontSize="18px"
                color="black.900"
              >
                Nomor Surat Tanda Registrasi (STR)
              </c-text>
              <c-text
                fontFamily="Roboto"
                fontWeight="500"
                fontSize="18px"
                color="black.900"
                marginBottom="20px"
              >
                {{ nutritionist.str }}
              </c-text>
              <c-text
                v-if="nutritionist.problemHandled"
                fontFamily="Roboto"
                fontWeight="500"
                fontSize="18px"
                color="black.900"
                marginBottom="10px"
              >
                Masalah yang dikuasai :
              </c-text>
              <c-list
                v-if="nutritionist.problemHandled"
                styleType="disc"
                marginBottom="20px"
              >
                <c-list-item
                  fontFamily="Roboto"
                  fontWeight="400"
                  fontSize="18px"
                  color="darkGray.900"
                  marginBottom="10px"
                >
                  Manajemen berat badan
                </c-list-item>
                <c-list-item
                  fontFamily="Roboto"
                  fontWeight="400"
                  fontSize="18px"
                  color="darkGray.900"
                  marginBottom="10px"
                >
                  Metabolic diet (DM, jantung, hipertensi,ginjal,asam urat)
                </c-list-item>
              </c-list>
              <c-text
                v-if="nutritionist.languages"
                fontFamily="Roboto"
                fontWeight="500"
                fontSize="18px"
                color="black.900"
                marginBottom="10px"
              >
                bahasa yang digunakan :
              </c-text>
              <c-list
                v-if="nutritionist.languages"
                styleType="disc"
                marginBottom="20px"
              >
                <c-list-item
                  fontFamily="Roboto"
                  fontWeight="400"
                  fontSize="18px"
                  color="darkGray.900"
                  marginBottom="10px"
                >
                  {{ nutritionist.languages }}
                </c-list-item>
              </c-list>
              <c-text
                v-if="nutritionist.education"
                fontFamily="Roboto"
                fontWeight="500"
                fontSize="18px"
                color="black.900"
                marginBottom="10px"
              >
                Lulusan dari :
              </c-text>
              <c-list
                v-if="nutritionist.education"
                styleType="disc"
                marginBottom="20px"
              >
                <c-list-item
                  v-for="(item, index) in nutritionist.education"
                  :key="'edu' + index"
                  fontFamily="Roboto"
                  fontWeight="400"
                  fontSize="18px"
                  color="darkGray.900"
                  marginBottom="10px"
                  text-transform="capitalize"
                >
                  {{ item.level }} -
                  {{
                    item.university === "0"
                      ? item.otherUniversity
                      : getUniversityName(item.university)
                  }}
                </c-list-item>
              </c-list>
            </c-box>
          </c-flex>
        </c-box>
      </c-box>
    </c-box>
    <Footer />
  </c-box>
</template>

<script>
import Header from "@/components/header/index.vue";
import Footer from "@/components/Footer.vue";
import Breadcrumb from "@/components/breadcrumb.vue";

import { mapActions, mapGetters } from "vuex";
import generalMixin from "@/utils/general-mixins";

export default {
  name: "detailNutritionists",
  components: {
    Header,
    Footer,
    Breadcrumb,
  },
  mixins: [generalMixin],
  computed: {
    ...mapGetters({
      isAuthenticated: "auth/isAuthenticated",
      nutritionist: "clients/nutritionist",
      universities: "general/universities",
    }),
    nutritionistId() {
      return this.$route.params.nutritionistId;
    },
    fullname() {
      return (
        `${this.nutritionist.title ? this.nutritionist.title + " " : ""}` +
        this.nutritionist?.firstName +
        " " +
        this.nutritionist?.lastName
      );
    },
    availableProgram() {
      return this.$route.params.program;
    },
  },
  methods: {
    ...mapActions({
      getNutritionist: "clients/getNutritionistById",
      chooseNutritionist: "clients/chooseNutritionist",
      loadUniversities: "general/loadUniversities",
    }),
    onChooseNutritionist() {
      if (!this.availableProgram) return;
      this.chooseNutritionist({
        programId: this.availableProgram.id,
        nutritionist: {
          nutritionistId: this.nutritionistId,
        },
      })
        .then((it) => {
          console.log(it);
          this.setSuccessMessages({
            title: "Pilih Ahli Gizi",
            body: "Berhasil Pilih Ahli Gizi",
            description: "Kamu berhasil memilih ahli gizi",
          });
          this.$router
            .push({
              name: "client.success",
              query: { program_id: this.availableProgram.id },
            })
            .catch();
        })
        .catch((err) => {
          this.$toast({
            title: "Failed",
            description: err.data.message || "Ops! Something when wrong.",
            status: "error",
            duration: 5000,
            position: "bottom-right",
            variant: "subtle",
          });
        });
    },
    getUniversityName(id) {
      if (this.universities.length === 0) return "";
      let a = this.universities.find((dt) => dt.id.toString() === id);
      return a.name;
    },
  },
  mounted() {
    let self = this;
    self.loadUniversities();
    if (!self.isAuthenticated) return self.$router.push({ name: "auth.login" });
    self.getNutritionist(this.nutritionistId);
  },
};
</script>

<style>
</style>
